/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unreachable */
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';

import styled from 'styled-components';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { useSearchParams, useLocation } from 'react-router-dom';
import TagCloudJS from 'TagCloud';
import useStateTool from '../Hooks/useStateTool';
import {
  disableGhostList,
  filterFlow,
  filterGlobalTags,
  replaceFlow,
  setFlow,
  setGlobalTags,
  setTempTag,
  toggleGhostList,
  updateSpesificFlow,
} from '../Redux/templateSlice';
import Word, { WordStyle } from '../Components/Word';
import useFindNext from '../Hooks/useFindNext';
import useTempTagHandler from '../Hooks/useTempTagHandler';
import useCheckNext from '../Hooks/useCheckNext';
import {
  removeCloudTag,
  setCloudClicked,
  setCloudTags,
  setGlobalCase,
} from '../Redux/stepsSlice';
import woopraHandler from '../woopra';
import TagCloud from '../Components/TagCloud/Index';
import SearchBar from '../Components/SearchBar';
import Tag from '../Components/TagCloud/Tag';
import useTags from '../Hooks/useTags';

const Wrapper = styled.div`
  align-items: center;
  /* background-image: url('https://cognni.ai/wp-content/uploads/2022/02/Clouds-move.gif?id=10305'); */
  background: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  gap: 1rem;
  /*   */
  /* justify-content: center; */
  min-height: 0px;
  /* padding: 2rem 2rem 5rem 2rem; */
  .wrapper {
    display: grid;
    gap: 1rem;
    grid-auto-flow: column;
    justify-content: center;
  }
  .title_back {
    align-self: center;
    cursor: pointer;
    font-size: 2.2rem;
  }
  .title_mobile {
    @media (max-width: 500px) {
      font-size: 2rem;
      padding: 2rem;
    }
  }
`;

const CardWrapper = styled.div`
  background: #fff;
  display: grid;
  gap: 1rem;
  margin-bottom: 2rem;
  /* padding: 0rem 4rem 4rem 4rem; */
  * {
    color: #33475b;
  }
  .cards-title {
    border-bottom: 1px solid #33475b3e;
    font-size: 3.5rem;
    font-weight: 600;
    margin-bottom: 2rem;
    margin-top: 3rem;
    padding-bottom: 2rem;
    width: 100%;
  }
  .cards-box {
    /* border: solid 1px black; */
    background: #fff;
    box-shadow: 0 1px 24px 0 rgb(0 0 0 / 8%);
    margin: 0 auto;
    max-width: 800px;
    padding: 1rem 3rem;
    width: 100%;
    @media (max-width: 50em) {
      display: grid;
      gap: 1rem;
    }
  }

  .content {
    display: none;
    margin: 0 auto;
    max-width: 600px;
    width: 100%;
    @media (min-width: 2560px) {
      display: block;
    }
  }
  .content-picked {
    display: none;
    gap: 1rem;
    grid-auto-flow: column;
    justify-content: flex-start;
    justify-items: flex-start;
    div {
      display: grid;
      gap: 0.5rem;
      grid-auto-flow: column;
      place-items: center;
      img {
        cursor: pointer;
        max-width: 18px;
      }
    }
    @media (min-width: 2560px) {
      display: grid;
    }
  }
  .cloudtag-mobile {
    display: block;
    * {
      color: #fff;
    }
    margin-top: 2rem;

    /* @media (min-width: 2560px) {
      display: none;
    } */
  }
`;
const Card = styled.div`
  cursor: pointer;
  font-size: 1.5rem;
  &:hover {
    color: #33475bd1;
  }

  /* padding: 0rem 0; */
`;

// start with initial word and show a underscore
// on mouse to underscore show options that previous word includes the tags for next one
// on click on option, add to tags and show next word

function Home({ setSearch, search }) {
  /* HOOKS */
  const { filteredTags } = useTags();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const pageId = searchParams.get('id');
  const mode = searchParams.get('mode');
  const tags = searchParams.get('tags');
  const preview = searchParams.get('preview');
  let cloud;
  const { state } = useStateTool();
  const data = state?.template?.data;
  const steps = state?.step?.data;
  const globalCase = state?.step?.globalCase;
  const globaltags = state.template.globalTags;
  const step = state?.step;

  // UseStates
  const [stepState, setStepState] = useState([]);
  const [finished, setFinished] = useState(false);
  const [tempStepId, setTempStepId] = useState(null);
  const [tempStepName, setTempStepName] = useState(null);
  const [howtoSentence, setSentence] = useState(false);
  const [relatedTags, setRelatedTags] = useState([]);
  const [relatedTagsChanged, setRelatedTagsChanged] = useState(false);
  const [cloudState, setCloudState] = useState([]);
  const [filteredCloudState, setFilteredCloudState] = useState([]);

  const [firstPick, setFirstPick] = useState(true);
  const [size1, setsize1] = useState([]);
  const [size2, setsize2] = useState([]);
  const [size3, setsize3] = useState([]);
  const [size4, setsize4] = useState([]);
  const [size5, setsize5] = useState([]);

  const findInitial = data?.find((item) => !item.conditions);
  const dispatch = useDispatch();
  // Push all words that will show up
  const flow = state?.template?.flow;
  const { tempTagHandler } = useTempTagHandler();
  const { checkNextExist, checkNextValue } = useCheckNext();
  const [pickedWord, setPickedWord] = useState(null);
  const { tempTag } = state.template;
  // Hooks
  useFindNext();

  // Refs
  const sentence = useRef(null);
  const iframe = useRef(null);
  const content = useRef(null);

  // Functions
  const flowUpdateHandler = (d) => {
    // Find index
    const index = flow.findIndex((item) => item.related === d.related);
    dispatch(updateSpesificFlow({ index, data: { choosen: true, ...d } }));
  };

  function completeAuto() {
    if (state.template.ghostList.length === 1) {
      flowUpdateHandler({
        choosen: true,
        ...state.template.ghostList[0],
        autocompleted: true,
      });
      tempTagHandler(state.template.ghostList[0].tags.map((item) => item.name));
    }
  }

  function breakChainHandler() {
    const unUsedTag = state.template.lastUpdated?.tags[0]?.name;
    const findIndex = flow.findIndex((item) => item.related === unUsedTag);

    if (unUsedTag && unUsedTag === state.template.flow[findIndex]?.related) {
      dispatch(filterFlow({ index: findIndex }));
    }
  }

  function checkTagHasNext() {
    if (globaltags?.length > 0) {
      const lastTag = globaltags[globaltags.length - 1];
      if (!checkNextExist(lastTag)) {
        setFinished(true);
        document
          .getElementById('iframe')
          .scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  function clearDuplicate() {
    flow.forEach((item) => {
      const textEqRelated = flow.find(
        (f) => item.text === f.related?.replace(/_/g, ' ') && !f.choosen
      );

      if (textEqRelated && !checkNextExist(textEqRelated?.related)) {
        // filter flow
        const newFlow = flow.filter(
          (f) => f.related !== textEqRelated?.related && f.choosen
        );
        dispatch(replaceFlow(newFlow));
      }
    });
  }

  function splitWords(text) {
    // only if text is string
    if (typeof text === 'string') {
      return text
        .split('_')
        .join(' ')
        .toLowerCase()
        .replace(/\s+/g, ' ')
        .split(' ')
        .filter((item) => item !== '');
    }
    return text;
  }

  const blackListedWords = ['how to', 'how', 'to'];
  const filterSpesificWord = (word) => {
    if (blackListedWords.includes(word)) {
      return false;
    }
    return true;
  };

  const handleTags = () => {
    const arr = [];

    // Always find the sentences similar the most
    const newSteps = cloudState.filter((s) => {
      const sentence = splitWords(s?.name?.replace('?', ''));

      sentence.forEach((ctx) => {
        // Filter Single Words
        filteredTags?.forEach((f) => {
          if (
            !/\s/.test(f.value.toLowerCase()) &&
            sentence.includes(f.value.toLowerCase()) &&
            !arr.includes(f.preview)
          ) {
            return arr.push(f.preview);
          }
          // Filter words has white space, make them exact match
          if (
            /\s/.test(f.value.toLowerCase()) &&
            s?.name.toLowerCase().includes(f.value.toLowerCase()) &&
            !arr.includes(f.preview) &&
            !state.step.cloudTagsPreviews.includes(f.preview.toLowerCase())
          ) {
            return arr.push(f.preview);
          }
        });
        // if (!arr.includes(ctx) && filterSpesificWord(ctx)) {
        //   arr.push(ctx);
        // }
      });
      // filteredTags?.forEach((f) => {
      //   if (s?.name?.includes(f?.preview)) {
      //     arr.push(f.preview);
      //   }
      // });
    });
    setRelatedTags(arr);
    setRelatedTagsChanged(!relatedTagsChanged);
  };

  const findOrder = (value) => {};

  useEffect(() => {
    const order1 = [];
    const order2 = [];
    const order3 = [];
    const order4 = [];
    const order5 = [];

    const allTags = state.template.sanityTags;
    allTags.forEach((aTag) => {
      const found = relatedTags.find((rTag) => {
        if (aTag.hide) return false;
        if (rTag?.toLowerCase() === aTag.preview?.toLowerCase()) {
          // Handle priority here
          if (aTag.priority > 0 && aTag.priority <= 3) {
            return order1.push(rTag);
          }
          if (aTag.priority > 3 && aTag.priority <= 6) {
            return order2.push(rTag);
          }
          if (aTag.priority > 6 && aTag.priority <= 9) {
            return order3.push(rTag);
          }
          if (aTag.priority > 9 && aTag.priority <= 1000) {
            return order4.push(rTag);
          }
          // Rest of the tags
          if (!aTag.priority) {
            return order5.push(rTag);
          }
        }
      });
    });
    setRelatedTags([...order1, ...order2, ...order3, ...order4, ...order5]);
    // setRelatedTags([]);
  }, [relatedTagsChanged]);

  // Initial
  useEffect(() => {
    // Push initial
    if (findInitial) {
      dispatch(setFlow([{ choosen: true, ...findInitial }]));
      // Temp tag that will find ghost list and show underline if it is not empty
      dispatch(setTempTag(findInitial?.tags?.map((item) => item.name)));
    }
    setStepState(steps);
    setCloudState(steps);
  }, []);

  useEffect(() => {
    handleTags();
  }, [cloudState]);

  // Every time make a search, filter cloud state
  useEffect(() => {
    if (search) {
      const newSteps = cloudState.filter((s) =>
        s?.name?.toLowerCase().includes(search.toLowerCase())
      );
      setCloudState(newSteps);
    } else {
      setCloudState(steps);
    }
  }, [search]);

  // Disable ghost list for end of the option
  // All questions completed
  useEffect(() => {
    // const element = document.getElementById('iframe');
    // element.scrollIntoView({ behavior: 'smooth' });
    dispatch(disableGhostList());
    clearDuplicate();
  }, [flow]);

  // When a change happens in flow
  useEffect(() => {
    breakChainHandler();
    dispatch(setGlobalTags(state.template.tempTag));
  }, [state.template.tempTag]);

  useEffect(() => {
    checkTagHasNext();
    if (pickedWord) {
      woopraHandler('word_picked', {
        current_sentence: globalCase,
        picked_word: pickedWord,
      });
    }
  }, [state.template.globalTags]);

  // Auto complete
  useEffect(() => {
    completeAuto();
  }, [state.template.ghostList]);

  useEffect(() => {
    if (pageId) {
      setSentence(stepState.find((item) => item?.id?.current === pageId)?.name);
    }
  }, [stepState]);

  // when ghostList toggled
  useEffect(() => {
    if (state.template.showGhostList) {
      woopraHandler('word_choosing', {
        current_sentence: globalCase,
        choosing_options: state.template.ghostList?.map((item) => item.text),
      });
    }
  }, [state.template.showGhostList]);

  // Check current innerText
  useEffect(() => {
    if (sentence.current && !state.template.showGhostList) {
      const currentText = sentence.current.innerText;
      dispatch(setGlobalCase(splitWords(currentText)));
    }
  }, [sentence.current?.innerText]);

  useEffect(() => {
    // Always find the sentences similar the most
    const newSteps = steps.filter((s) => {
      // compare sentence and global
      const sentence = splitWords(s?.name?.replace('?', ''));

      const arr = [];
      sentence?.map((a, i) => {
        if (a === globalCase[i]?.replace('?', '')) {
          arr.push(a);
        }
        return arr;
      });

      if (arr.length === globalCase.length) {
        return s;
      }
      return false;
    });

    setStepState(newSteps);
  }, [globalCase]);

  // Filter cloud tags
  useEffect(() => {
    const { cloudTags } = step;

    if (cloudTags.length > 0) {
      setFirstPick(false);
    }

    if (cloudTags.length === 0) {
      setFirstPick(true);
    }

    const arr = [];
    // Always find the sentences similar the most
    const newSteps = steps.filter((s) => {
      const sentence = splitWords(s?.name?.replace('?', ''));
      const equal = [];
      sentence.forEach((item) => {
        if (cloudTags.includes(item)) {
          equal.push(item);
        }

        if (equal.length === cloudTags.length && !arr.includes(s)) {
          arr.push(s);
        }
        return arr;
      });
      return arr;
    });

    if (step.cloudTags.length === 0) {
      return setCloudState(steps);
    }

    const filtered = arr.filter((item) => {
      if (
        item?.name
          ?.toLowerCase()
          ?.includes(state?.step?.cloudTagsPreviews[0]?.toLowerCase()) ||
        item?.name
          ?.toLowerCase()
          ?.includes(state?.step?.cloudTagsPreviews[1]?.toLowerCase()) ||
        item?.name
          ?.toLowerCase()
          ?.includes(state?.step?.cloudTagsPreviews[2]?.toLowerCase())
      ) {
        return item;
      }
    });
    // Filter by tags
    setCloudState(filtered);
  }, [step.cloudTags]);

  useEffect(() => {
    if (tempStepId) {
      document.getElementById('iframe').scrollIntoView({ behavior: 'smooth' });
      navigate(`?tags=${tags}&preview=${preview}&id=${tempStepId}`);
    }
  }, [tempStepId]);

  // After a question completed
  useEffect(() => {
    if (finished) {
      woopraHandler('question_completed', {
        current_sentence: globalCase,
        step_url: stepState[0]?.id.current
          ? `https://playbook.cognni.ai/?id=${stepState[0]?.id.current}`
          : 'no 3step found related',
      });
    }
  }, [finished]);

  useEffect(() => {
    if (cloudState.length === 1) {
      // setFinished(true);
      setSentence(cloudState[0]?.name);
    }
  }, [cloudState]);

  // useEffect(() => {
  //   const element = document.querySelector('.tagcloud', {
  //     radius: '400px',
  //   });
  //   if (element) {
  //     element.remove();
  //   }

  //   cloud = TagCloudJS('.content', relatedTags, {
  //     radius: 300,
  //   });
  //   cloud?.pause();
  // }, [cloudState, relatedTags]);

  const priorityTable = [
    {
      color: '#008FFB',
      range: [0, 3],
    },
    {
      color: '#5BC0EB',
      range: [3, 6],
    },
    {
      color: '#FFC300',
      range: [6, 9],
    },
    {
      color: '#00B746',
      range: [9, 100],
    },
    // {
    //   color: 'red',
    //   range: [100, 32000],
    // },
  ];

  useEffect(() => {
    const tagCloudItems = document.querySelectorAll('.tagcloud--item');

    tagCloudItems.forEach((item) => {
      const found = state.template.sanityTags.find(
        (ctx) => ctx.name?.toLowerCase() === item.innerText?.toLowerCase()
      );

      priorityTable.forEach((ctx) => {
        if (found?.priority > ctx.range[0] && found?.priority < ctx.range[1]) {
          item.style.color = ctx.color;
        }

        if (!found?.priority) {
          // item.style.color = 'red';
        }
      });

      item.addEventListener('click', (e) => {
        const tag = e.target.innerText;
        dispatch(setCloudTags(tag));
      });
    });
  }, [relatedTags, state.step.cloudTags]);

  // Detect removed tag in globalTags
  // useEffect(() => {
  //   if (state.template.globalTags?.length > 0) {
  //     flow.forEach((item) => {
  //       if (item.choosen) {
  //         const tag = item.tags.map((item) => item.name)[0];
  //         const { globalTags } = state.template;
  //         globalTags.map((item) => {
  //           if (item === tag) {
  //             dispatch(setGlobalTags([tag]));

  //             return true;
  //           }
  //           dispatch(filterGlobalTags(tag));
  //           return false;
  //         });
  //       }
  //     });
  //   }
  // }, [state.template.globalTags]);

  const handleBack = (fromId = false) => {
    if (fromId) {
      setFinished(false);
      setTempStepId(null);
      return navigate(`?tags=${tags}&preview=${preview}`);
    }
    setFinished(false);
    setTempStepId(null);
  };

  if (!findInitial) return <div>LOADING...</div>;

  return (
    <div id='home'>
      <Wrapper>
        {!tempStepName &&
        !howtoSentence &&
        !step.clickedOnCloud &&
        mode === 'manuel' ? (
          <div className='wrapper' ref={sentence}>
            {flow.map((item, index) => (
              <Word
                setPickedWord={setPickedWord}
                checkNextValue={checkNextValue}
                checkNextExist={checkNextExist}
                finished={finished}
                setFinished={setFinished}
                lastTag={flow[flow.length - 1]}
                key={item?._id}
                choosen={item?.choosen}
                lastIndex={flow.length - 1}
                indexNumber={index}
                {...item}
              />
            ))}
          </div>
        ) : (
          finished &&
          !pageId && (
            <WordStyle
              className='title_mobile'
              style={{
                marginTop: '7rem',
                marginBottom: '0',
                position: 'relative',
                zIndex: '3',
                display: 'flex',
                gap: '2rem',
              }}
            >
              <i
                onClick={() => handleBack(false)}
                className='fas fa-arrow-left title_back'
              />
              {tempStepName || howtoSentence}
            </WordStyle>
          )
        )}
        {pageId && (
          <WordStyle
            className='title_mobile title_web'
            style={{
              marginTop: '7rem',
              marginBottom: '0',
              position: 'relative',
              zIndex: '3',
              display: 'flex',
              gap: '2rem',
            }}
          >
            <i
              onClick={() => handleBack(true)}
              className='fas fa-arrow-left title_back'
            />
            {tempStepName || howtoSentence}
          </WordStyle>
        )}
        {/* {!tempStepId && !pageId && !mode && !finished && (
          <SearchBar setSearch={setSearch} />
        )} */}
      </Wrapper>

      <CardWrapper id='iframe'>
        {!finished && mode && <SearchBar setSearch={setSearch} />}

        {!tempStepId && !pageId && !mode && filteredTags && (
          <TagCloud
            cloudTags={step.cloudTags}
            dispatch={dispatch}
            setGlobalTags={setGlobalTags}
            tags={relatedTags}
            globalTags={globaltags}
            preview={step.cloudTagsPreviews}
          />
        )}

        {!mode && !finished && (
          <div>
            <div
              className='content'
              ref={content}
              onMouseEnter={() => cloud.resume()}
              onMouseLeave={() => cloud.pause()}
            />
            {/* <div className='content-picked'>
              {state.step.cloudTags?.map((picked) => (
                <div>
                  {picked}
                  <img
                    onClick={() => {
                      dispatch(
                        removeCloudTag({
                          removed: picked,
                        })
                      );
                    }}
                    src='/close.svg'
                    alt=''
                  />
                </div>
              ))}
            </div> */}
          </div>
        )}

        {!finished && !pageId && (
          <div className='cards-box'>
            {stepState?.length > 1 && !tempStepId && !pageId && !finished && (
              <div className='cards-title'>How To</div>
            )}
            {stepState?.length > 1 &&
            !tempStepId &&
            !pageId &&
            !step.clickedOnCloud
              ? stepState
                  .filter((f) =>
                    f?.name?.toLowerCase().includes(search.toLowerCase())
                  )
                  .map(
                    (a) =>
                      a.name && (
                        <Card
                          onClick={() => {
                            setTempStepId(a?.id.current);
                            setFinished(true);
                            setTempStepName(a?.name);
                            woopraHandler('clicked_FAQ', {
                              current_sentence: globalCase,
                              clicked_FAQ: a?.name,
                              step_url: `https://playbook.cognni.ai/?id=${a?.id.current}`,
                            });
                          }}
                        >
                          {a?.name}
                        </Card>
                      )
                  )
              : !tempStepId &&
                !pageId &&
                step.clickedOnCloud &&
                !finished &&
                cloudState
                  .filter((f) => {
                    if (f?.name?.toLowerCase().includes(search.toLowerCase())) {
                      return f;
                    }
                  })
                  .map(
                    (a) =>
                      a.name && (
                        <Card
                          onClick={() => {
                            setTempStepId(a?.id.current);
                            setFinished(true);
                            setTempStepName(a?.name);
                            woopraHandler('clicked_FAQ', {
                              current_sentence: globalCase,
                              clicked_FAQ: a?.name,
                              step_url: `https://playbook.cognni.ai/?id=${a?.id.current}`,
                            });
                          }}
                        >
                          {a?.name}
                        </Card>
                      )
                  )}
          </div>
        )}
      </CardWrapper>

      {pageId && (
        <iframe
          id='step'
          title='popup'
          src={`https://playbook.cognni.ai/?id=${pageId}&mode=howto`}
          frameBorder='0'
          width='100%'
          height='800px'
        />
      )}
      {/* {stepState?.length === 1 ||
        (tempStepId && finished && (
          <iframe
            id='step'
            title='popup'
            src={`https://irecommend.cognni.ai/?id=${
              tempStepId || stepState[0].id.current || cloudState[0].id.current
            }&mode=howto`}
            frameBorder='0'
            width='100%'
            height='800px'
            ref={iframe}
          />
        ))} */}
      {/* {stepState[0]?.id.current && stepState?.length === 1 && (
        <iframe
          id='step'
          title='popup'
          src={`https://irecommend.cognni.ai/?id=${stepState[0].id.current}&mode=howto`}
          frameBorder='0'
          width='100%'
          height='800px'
        />
      )} */}

      {/* {cloudState[0]?.id.current && finished && (
        <iframe
          id='step'
          title='popup'
          src={`https://irecommend.cognni.ai/?id=${cloudState[0].id.current}&mode=howto`}
          frameBorder='0'
          width='100%'
          height='800px'
        />
      )} */}

      {(finished || pageId) && (
        <a href='/' className='ask_another'>
          Ask Another Question
        </a>
      )}
    </div>
  );
}

export default Home;
