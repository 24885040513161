const sanityClient = require('@sanity/client');

const client = sanityClient({
  projectId: '7f3wklqu',
  dataset: 'production',
  apiVersion: '2021-12-08', // use current UTC date - see "specifying API version"!
  token:
    'sk1bewm1yqIGMCSbR1OK6ZlRlY5aZ7ZlHk0tEzo1RDgvJVpfMeTSerK2JjrdK5MjgyMOroh9zj7uvWOTYxjPeVW7LKRKNpCnPl5vOjeppHBm53KMewVuaCv01hx5uhu0m4qfFTDcRu23ragcucLnmEetTLCmmCxrxRuWj22eGUYtWiCH42p2', // or leave blank for unauthenticated usage
  useCdn: true, // `false` if you want to ensure fresh data
  ignoreBrowserTokenWarning: true,
});

export default client;
