const sanityClient = require('@sanity/client');
// Sanity Settings
const client = sanityClient({
  projectId: '4q1ozgmo',
  dataset: 'production',
  apiVersion: '2021-10-21', // use current UTC date - see "specifying API version"!
  token:
    'skMOBCV7o343X53hUOJ3kcQ9co3kd0UgBG98b0dtEWOJzkJClTB8ufjp1983y4luvMGd0e3GAm7q8xnOpdxUavQSGDRgdwc0di7LViaEUp9SEVb0cNJLPQiSNWE2RJGe7REAPLLVEEQB8oYCS3tuS3cVMH5x8T7SKbkF1MiszzQdwZIoGcrT',
  useCdn: false, // `false` if you want to ensure fresh data
});

export default client;
