/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { setTempTag } from '../../Redux/templateSlice';
import { removeCloudTag, setCloudTags } from '../../Redux/stepsSlice';

const TagStyle = styled.div`
  background: ${(props) => props.bg};
  border-radius: 0.5rem;
  color: #fff;
  cursor: pointer;
  display: grid;
  flex-basis: ${(props) => props.flexGrow};
  font-size: ${(props) => props.fontSize};
  gap: 0.5rem;
  grid-auto-flow: column;
  /* justify-content: space-between; */
  padding: 0.5rem 1rem;
  place-items: center;
  text-align: center;
  img {
    max-width: 12px;
    &:hover {
      opacity: 0.4;
    }
  }

  &:hover {
    opacity: 0.8;
  }
`;

function Tag({
  text,
  totalAmount,
  index,
  fontSize,
  randomColor,
  flexGrow,
  close,
  pickedWords,
}) {
  const dispatch = useDispatch();

  const removeTag = () => {
    dispatch(
      removeCloudTag({
        removed: text,
      })
    );
  };

  return (
    <TagStyle
      style={
        pickedWords?.includes(text)
          ? { display: 'none', pointerEvents: 'none' }
          : {}
      }
      onClick={() => {
        if (!close) {
          // dispatch(setTempTag([text]));
          dispatch(setCloudTags(text));
        }
      }}
      fontSize={fontSize}
      bg={randomColor}
      flexGrow={flexGrow}
    >
      {text}
      {close && <img onClick={removeTag} src='/close.svg' alt='' />}
    </TagStyle>
  );
}

export default React.memo(Tag);

Tag.propTypes = {
  text: propTypes.string.isRequired,
  totalAmount: propTypes.number.isRequired,
  index: propTypes.number.isRequired,
  fontSize: propTypes.string.isRequired,
  randomColor: propTypes.string.isRequired,
  flexGrow: propTypes.string.isRequired,
  close: propTypes.bool.isRequired,
  pickedWords: propTypes.string.isRequired,
};
