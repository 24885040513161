import { useSelector } from 'react-redux';

const useCheckNext = () => {
  const state = useSelector((s) => s.template);
  const { data } = state;

  const checkNextExist = (tag) => {
    const find = data
      .map((item) => item.conditions?.find((i) => i.name === tag))
      .filter((d) => d);
    return find.length > 0 || false;
  };

  const checkNextValue = (tag) => {
    const find = data
      .map((item) => {
        if (item.conditions?.find((i) => i.name === tag)) return item;
        return false;
      })
      .filter((d) => d);
    return find || false;
  };

  return { checkNextExist, checkNextValue };
};

export default useCheckNext;
