/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';

const SearchBarStyle = styled.div`
  align-items: center;

  /* margin-top: 4rem; */
  box-shadow: 0 1px 24px 0 rgb(0 0 0 / 8%);
  display: grid;
  justify-self: center;
  /* margin-top: 1rem; */
  max-width: 800px;
  position: relative;
  width: 100%;
  z-index: 0;
  .kb-search__mag {
    left: 1.5rem;
    position: absolute;
  }
  input {
    border: none;
    border-radius: 3px;
    height: 48px;
    outline: none;
    padding: 1rem 4rem;
    width: 100%;
    ::placeholder {
      color: #7c98b6;
    }
    @media (min-width: 50em) {
      width: 400px;
    }
  }
`;

function SearchBar({ setSearch }) {
  return (
    <SearchBarStyle>
      <svg
        className='kb-search__mag'
        width='15'
        height='15'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M14.02 12.672l-3.64-3.64a5.687 5.687 0 0 0 1.06-3.312A5.726 5.726 0 0 0 5.72 0 5.726 5.726 0 0 0 0 5.72a5.726 5.726 0 0 0 5.72 5.72 5.687 5.687 0 0 0 3.311-1.06l3.641 3.64a.95.95 0 0 0 1.348 0 .953.953 0 0 0 0-1.348zm-8.3-3.139a3.813 3.813 0 1 1 0-7.626 3.813 3.813 0 0 1 0 7.626z'
          fill='#7c98b6'
          fillRule='evenodd'
        />
      </svg>
      <input
        type='text'
        placeholder='Search your question'
        onChange={(e) => setSearch(e.target.value)}
      />
    </SearchBarStyle>
  );
}

export default SearchBar;
