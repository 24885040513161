/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useRef } from 'react';

// eslint-disable-next-line react/prop-types
function AnimatedHamburger({ setMobActive, mobActive }) {
  const anim = useRef();
  const animateHandler = () => {
    anim.current.classList.toggle('animate');
    setMobActive(!mobActive);
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div onClick={animateHandler} className='menu-wrapper'>
      <div ref={anim} className='hamburger-menu' />
    </div>
  );
}

export default AnimatedHamburger;
