import { useSelector } from 'react-redux';
import { useState, useEffect, memo } from 'react';

const useTags = () => {
  const [filteredTags, setFilteredTags] = useState(false);
  const STATE = useSelector((state) => state.template);
  const { sanityTags } = STATE;

  useEffect(() => {
    const tags = [];
    if (filteredTags) return;
    sanityTags.forEach((tag) => {
      const { name, preview, hide, priority } = tag;
      if (hide) return;

      tags.push({
        value: name?.toLowerCase(),
        preview: preview?.toLowerCase() || name?.toLowerCase(),
        priority,
      });
      setFilteredTags(tags);
    });
  }, [sanityTags]);

  return { filteredTags };
};

export default useTags;
