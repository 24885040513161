import { useSelector, useDispatch } from 'react-redux';

function useStateTool() {
  return {
    state: useSelector((state) => state),
    dispatch: useDispatch(),
  };
}

export default useStateTool;
