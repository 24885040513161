import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setFlow, setGhostList } from '../Redux/templateSlice';

const useFindNext = () => {
  const state = useSelector((ctx) => ctx.template);
  const { flow } = state;
  const dispatch = useDispatch();

  const unchoosen = {
    related: state.tempTag[0],
    choosen: false,
    text: '',
    tags: [],
  };

  const flowHandler = () => {
    // Find next words
    // Compare state.tempTag with state.data.conditions.name
    // If it is equal, push to flow
    const nextWords = state.data.filter((item) => {
      if (item.conditions) {
        return item.conditions.some((condition) =>
          state.tempTag.includes(condition.name)
        );
      }
      return false;
    });
    // Problem with tempTag
    if (nextWords?.length > 0) {
      if (!flow?.find((item) => item.related === state.tempTag[0])) {
        dispatch(setFlow([unchoosen]));
      }
      dispatch(
        setGhostList(
          nextWords.map((item) => ({ ...item, related: state.tempTag[0] }))
        )
      );
    }
  };

  useEffect(() => {
    flowHandler();
  }, [state.tempTag]);
};

export default useFindNext;
