/* eslint-disable no-unused-vars */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { PAGE_QUERY, TAG_QUERY } from '../queries';
import client from '../sanity';
import recommend from '../recommendSanity';

const initialState = {
  data: [],
  loading: false,
  globalTags: [],
  flow: [],
  tempTag: [],
  ghostList: [],
  showGhostList: false,
  lastUpdated: null,
  sanityTags: [],
};

export const fetchData = createAsyncThunk('/api/sanity/template', async () => {
  const sanity = await client.fetch(PAGE_QUERY, {});
  return sanity;
});

export const fetchTags = createAsyncThunk('/api/sanity/tags', async () => {
  const sanity = await recommend.fetch(TAG_QUERY, {});
  return sanity;
});

export const templateSlice = createSlice({
  name: 'template',
  initialState,
  reducers: {
    setGlobalTags: (state, action) => {
      const { payload } = action;
      const find = state.globalTags.find((tag) => tag === payload[0]);
      if (find) return;
      state.globalTags = [...state.globalTags, ...payload];
    },

    filterGlobalTags: (state, action) => {
      const { payload } = action;
      state.globalTags = state.globalTags.filter((tag) => tag !== payload);
    },
    setFlow: (state, action) => {
      // Prevent duplicates
      const find = state.flow.find(
        (item) => item.related === action.payload[0].related
      );
      if (find) return;
      state.flow = [...state.flow, ...action.payload];
    },
    replaceFlow: (state, action) => {
      state.flow = action.payload;
    },
    updateSpesificFlow: (state, action) => {
      const { index, data } = action.payload;
      state.lastUpdated = state.flow[index];
      state.flow[index] = data;
    },
    filterFlow: (state, action) => {
      // remove from the flow by index number
      const { index } = action.payload;
      state.flow = [
        ...state.flow.slice(0, index),
        {
          related: state.tempTag[0],
          choosen: false,
          text: '',
          tags: [],
        },
      ];
    },
    setTempTag: (state, action) => {
      state.tempTag = action.payload;
    },
    setGhostList: (state, action) => {
      state.ghostList = action.payload;
    },
    toggleGhostList: (state, action) => {
      state.showGhostList = !state.showGhostList;
    },
    // disable ghost list
    disableGhostList: (state, action) => {
      state.showGhostList = false;
    },
  },
  extraReducers: {
    [fetchData.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchData.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    [fetchData.rejected]: (state, action) => {
      state.loading = false;
    },
    [fetchTags.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchTags.fulfilled]: (state, action) => {
      state.sanityTags = action.payload;
      state.loading = false;
      const tgas = action.payload.map((tag) => console.log(tag.name));
    },
    [fetchTags.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const {
  setGlobalTags,
  setFlow,
  setTempTag,
  setGhostList,
  toggleGhostList,
  updateSpesificFlow,
  filterFlow,
  disableGhostList,
  replaceFlow,
  filterGlobalTags,
} = templateSlice.actions;
export default templateSlice.reducer;
