import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setTempTag } from '../Redux/templateSlice';

const useTempTagHandler = () => {
  const dispatch = useDispatch();
  const state = useSelector((s) => s.template);

  const tempTagHandler = (tag) => {
    // console.log(state.tempTag[0], tag[0]);
    if (state.tempTag[0] === tag[0]) return;
    dispatch(setTempTag(tag));
  };

  return { tempTagHandler };
};

export default useTempTagHandler;
