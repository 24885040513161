const handleSize = ({ numArray, divide, from }) =>
  numArray.slice(from, Math.floor(from + numArray.length / divide));

const divideArray = ({ arr, divideTo }) => {
  // divide array to spesific part of indicated number
};

module.exports = {
  handleSize,
};
