import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import Home from './Pages/Home';
import { fetchData, fetchTags } from './Redux/templateSlice';
import useStateTool from './Hooks/useStateTool';
import Header from './Components/Header';
import Footer from './Components/Footer';
import './App.scss';
import { fetchSteps } from './Redux/stepsSlice';
import { SignInButton } from './Components/SignInButton';
import { loginRequest } from './authConfig';
import { callMsGraph } from './graph';

const eventMethod = window.addEventListener
  ? 'addEventListener'
  : 'attachEvent';
const eventer = window[eventMethod];
const messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';

const sendMessageParent = ({ message }) => {
  window.parent.postMessage(message, '*');
};

function App() {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [search, setSearch] = React.useState('');

  // function RequestProfileData() {
  //   // Silently acquires an access token which is then attached to a request for MS Graph data
  //   instance
  //     .acquireTokenSilent({
  //       ...loginRequest,
  //       account: accounts[0],
  //     })
  //     .then((response) => {
  //       callMsGraph(response.accessToken).then((response) =>
  //         // setGraphData(response)
  //       );
  //     });
  // }

  useEffect(() => {
    if (isAuthenticated) {
      // RequestProfileData();
    }
  }, [isAuthenticated]);

  const dispatch = useDispatch();
  const { state } = useStateTool();

  // Fetch sanity data
  React.useEffect(() => {
    dispatch(fetchData());
    dispatch(fetchSteps());
    dispatch(fetchTags());
  }, []);

  return (
    <Routes>
      <Route
        path='/'
        element={
          state?.template?.loading || state?.step?.data?.length === 0 ? (
            <div>LOADING...</div>
          ) : (
            <>
              <Header setSearch={setSearch} />
              <main className='app'>
                {state.template.sanityTags.length > 0 && (
                  <Home search={search} setSearch={setSearch} />
                )}
              </main>
              <Footer />
            </>
          )
        }
      />
    </Routes>
  );
}

export default App;
