/* eslint-disable import/no-named-as-default */
import { configureStore } from '@reduxjs/toolkit';
import templateSlice from './templateSlice';
import stepSlice from './stepsSlice';

const store = configureStore({
  reducer: {
    template: templateSlice,
    step: stepSlice,
  },
});

export default store;
