import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { setCloudParamPreview, setCloudParams } from '../Redux/stepsSlice';

const useParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tags = searchParams.get('tags');
  const preview = searchParams.get('preview');
  const dispatch = useDispatch();

  useEffect(() => {
    if (tags && preview) {
      dispatch(setCloudParams([...tags.split(',')]));
      dispatch(setCloudParamPreview([preview]));
    }
  }, []);
};

export default useParams;
