/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import styled from 'styled-components';
import Collapsible from './Collapsible';
import TabMenu from './TabMenu';
import AnimatedHamburger from './AnimatedHamburger';
import SearchBar from './SearchBar';

// JSON Object of Header
const tabsObject = [
  {
    id: 0,
    name: 'Solutions',
    href: 'https://cognni.ai/use-cases/',
    menuList: [
      {
        name: 'Cloud Intelligence',
        href: 'https://cognni.ai/cloud-intelligence/',
      },
      {
        name: 'On-Premises Intelligence',
        href: 'https://cognni.ai/on-premises-mapping/',
      },
      {
        name: 'Automations',
        href: '/',
        extends: true,
        extendedTab: [
          {
            name: 'Microsoft Information Protection (MIP)',
            href: 'https://cognni.ai/cognni-apps/#MIP',
          },
          {
            name: 'Microsoft Cloud App Security (MCAS) – coming soon',
            href: 'https://cognni.ai/cognni-apps/#MCAS',
          },
          {
            name: 'Azure Sentinel – coming soon',
            href: 'https://cognni.ai/cognni-apps/#Sentinel',
          },
        ],
      },
    ],
  },
  {
    id: 1,
    name: 'Use Cases',
    href: 'https://cognni.ai/use-cases/',
    menuList: [
      {
        name: 'Govern All of Your Data',
        href: 'https://cognni.ai/wp-content/uploads/2020/08/Use-Case-Govern-All-of-Your-Data.pdf',
      },
      {
        name: 'Identify Appropriate Security Tools',
        href: 'https://cognni.ai/wp-content/uploads/2020/08/Use-Case-Identify-Appropriate.pdf',
      },
      {
        name: 'Implement Accurate Policies',
        href: 'https://cognni.ai/wp-content/uploads/2020/08/Use-Case-Implement-Accurate-Policies.pdf',
      },
      {
        name: 'Investigate Incidents in Depth',
        href: 'https://cognni.ai/wp-content/uploads/2020/08/Use-Case-Investigate-Incidents-in-Depth.pdf',
      },
      // {
      //   name: 'Enhanced Incident Remediation',
      //   href: 'https://cognni.ai/wp-content/uploads/2020/08/Use-Case-Enhanced-Incident-Remediation.pdf',
      // },
    ],
  },
];

// Styled Components
const Wrapper = styled.header`
  align-items: center;
  background: #082f50 !important;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  padding: 2rem 2.5rem;
  /* height: ${(props) => (props.expand ? '500px' : 'unset')}; */
  /* Navigation Buttons */

  .header_link {
    color: #fff;
  }

  .logo_header {
    display: grid;
    grid-auto-flow: column;
    align-items: flex-end;
    gap: 2rem;
    color: #fff;
    font-size: 1.5rem;
    p {
      margin-bottom: 0.4rem;
    }
  }

  .menu-btn {
    background: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0);
    border-radius: unset;
    padding-bottom: 0.3rem;
    transition: unset;
    &:hover {
      border-bottom: 1px solid black;
    }
  }

  span {
    cursor: pointer;
  }
  .tab-wrapper {
    align-items: center;
    display: flex;
    position: relative;
  }
  @media (min-width: 50em) {
    padding: 3rem 6rem;
    background: unset;
  }
`;

const Logo = styled.img`
  max-width: 120px;
  @media (min-width: 50em) {
    max-width: 170px;
  }
`;

const NavigationWrapperWeb = styled.div`
  display: none;
  @media (min-width: 50em) {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    gap: 36px;
  }
`;

const Button = styled.a`
  background: ${(props) => (props.bg ? props.bg : '#FFF')};
  border: ${(props) =>
    props.border ? 'solid 1px rgba(52, 49, 76, 0.66)' : 'unset'};
  border-radius: 100px;
  color: ${(props) =>
    props.textColor ? props.textColor : 'rgba(52, 49, 76, 0.66)'};
  cursor: pointer;
  font-size: 1.6rem;
  font-weight: 400;
  padding: ${(props) => props.pd && '1rem 2.5rem !important'};
  transition: 0.3s;

  a {
    color: unset;
    text-decoration: unset;
  }
  &:hover {
    background: ${(props) => props.blue && '#1876c4'};
    color: ${(props) => (props.textColor ? props.textColor : '#333')};
  }
`;

function Header({ header, tabIndex, seTabIndex, setSearch }) {
  const [extented, setExtented] = useState(false);
  const [mobActive, setMobActive] = useState(false);

  // Default Tab Index
  const defaultTabIndex = () => {
    // After leave the mouse from the tab, remove menuList

    seTabIndex(null);
    setExtented(false);
  };

  return (
    <Wrapper
      expand={tabIndex !== null}
      ref={header}
      onMouseLeave={defaultTabIndex}
    >
      <div className='logo_header'>
        <Logo src='/cognni-logo-300x71.png' alt='Cognni' />
        <p>
          <a href='/'>how to</a>
        </p>
      </div>

      {/* <AnimatedHamburger setMobActive={setMobActive} mobActive={mobActive} /> */}

      <NavigationWrapperWeb>
        <SearchBar setSearch={setSearch} />
        {/* <a
          className='header_link'
          target='_parent'
          href='https://onboard.cognni.ai/login'
          pd
          border
        >
          <strong>Login</strong>
        </a> */}
      </NavigationWrapperWeb>
      {/* ONLY MOBILE */}
      {mobActive && <Collapsible tabsObject={tabsObject} />}
    </Wrapper>
  );
}

export default Header;
