/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { TagCloud } from 'react-tagcloud';
import { useNavigate } from 'react-router';
import Tag from './Tag';
import { handleSize } from '../../utils/tools';
import useParams from '../../Hooks/useParams';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  margin: 0 auto;
  margin-top: 2rem;
  max-width: 900px;
  width: 100%;
`;

const Container = styled.div`
  width: 100%;
  .picked_tags {
    color: gray;
    display: grid;
    font-size: 1.2rem;
    gap: 1rem;
    grid-auto-flow: column;
    justify-content: flex-start;
    justify-self: flex-start;
    margin: 0 auto;
    margin-top: 2rem;
    max-width: 800px;
  }
`;

const theme = [
  {
    color: '#FFF',
    background: '#008FFB',
  },
  {
    color: '#FFF',
    background: '#FFC300',
  },
  {
    color: '#FFF',
    background: '#00B746',
  },
  {
    color: '#FFF',
    background: '#5BC0EB',
  },
];

const index = ({
  tags,
  globalTags,
  setGlobalTags,
  dispatch,
  cloudTags,
  preview,
}) => {
  const navigate = useNavigate();

  const [selectedTags, setSelectedTags] = useState([]);

  const [fontSizes, setFontSizes] = useState([]);

  // Handle middle numbers
  const tagsLength = Math.round(selectedTags.length / 2);
  const middleNumbers = [tagsLength - 1, tagsLength, tagsLength + 1];
  let tempState = [];
  const pushSize = (size, flexGrow = 'unset') => {
    setFontSizes((prev) => [...prev, { size }]);
    tempState.push({ size, flexGrow });
  };

  const fontSizeHandler = () => {
    let lock = false;
    tempState = [];
    setFontSizes([]);
    selectedTags.forEach((S, index) => {
      // if (index < tagsLength && !middleNumbers.includes(index)) {
      //   if (index > 10) {
      //     setFontSizes((prev) => [...prev, { size: `1.${index}rem` }]);
      //     tempState.push({ size: `1.${index}rem` });
      //   } else {
      //     setFontSizes((prev) => [...prev, { size: `1.0${index}rem` }]);
      //     tempState.push({ size: `1.0${index}rem` });
      //   }
      // }

      if (lock) {
        return setFontSizes((prev) => [
          ...tempState,
          ...tempState.slice(0, middleNumbers[0] - 1).reverse(),
        ]);
      }

      if (middleNumbers.includes(index)) {
        if (middleNumbers[2] === index) {
          lock = true;
        }

        return pushSize('24px', '30%');
      }

      if (index < 3) {
        pushSize('10px');
      }

      if (index > 2 && index < 6) {
        pushSize('12px');
      }

      if (index > 5 && index < 9) {
        pushSize('14px');
      }

      if (index > 8 && index < 12) {
        pushSize('16px');
      }

      if (index > 11 && index < 15) {
        pushSize('18px');
      }
      if (index > 14 && index < 20) {
        pushSize('20px');
      }
    });
    // setFontSizes((prev) => [...tempState]);
  };

  useEffect(() => {
    if (!tags) return;
    setSelectedTags([]);
    if (globalTags?.length > 0) {
      tags.slice(0, 50)?.forEach((tag, index) => {
        setSelectedTags((prevState) => [
          ...prevState,
          { value: tag?.toLowerCase(), count: index + 1 },
        ]);
      });
    }
  }, [tags]);

  useEffect(() => {
    fontSizeHandler();
    // console.log(cloudTags);
    // console.log(preview);

    navigate(`?tags=${cloudTags}&preview=${preview}`);
  }, [selectedTags]);

  useParams();
  return (
    <Container className='cloudtag-mobile'>
      <Wrapper>
        {selectedTags
          .filter((tags) => selectedTags.length > 1)
          ?.map((tag, index) => (
            <Tag
              pickedWords={cloudTags}
              text={tag.value}
              totalAmount={selectedTags.length}
              index={index + 1}
              fontSize={fontSizes[index]?.size}
              flexGrow={fontSizes[index]?.flexGrow}
              randomColor={
                theme[Math.floor(Math.random() * theme.length)].background
              }
            />
          ))}
      </Wrapper>
      <div className='picked_tags'>
        {preview?.map((cloud, index) => (
          <Tag
            text={cloud}
            randomColor={
              theme[Math.floor(Math.random() * theme.length)].background
            }
            close
          />
        ))}
      </div>
    </Container>
  );
};

export default React.memo(index);
