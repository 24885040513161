/* eslint-disable no-unused-vars */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { STEPS_QUERY } from '../queries';
import client from '../stepsSanity';

const initialState = {
  data: [],
  loading: false,
  globalCase: '',
  clickedOnCloud: false,
  cloudTags: [],
  cloudTagsPreviews: [],
};

export const fetchSteps = createAsyncThunk('/api/sanity/steps', async () => {
  const sanity = await client.fetch(STEPS_QUERY, {});
  return sanity;
});

export const stepSlice = createSlice({
  name: 'steps',
  initialState,
  reducers: {
    setGlobalCase: (state, action) => {
      state.globalCase = action.payload;
    },
    setCloudTags: (state, action) => {
      // let lock = false;
      // state.cloudTags.forEach((tag) => {
      //   if (action.payload.split(' ').find((a) => a === tag)) {
      //     lock = true;
      //   }
      // });

      // if (lock) {
      //   lock = false;
      //   return;
      // }

      if (!state.clickedOnCloud) {
        state.clickedOnCloud = true;
      }
      // state.cloudTags = action.payload;
      state.cloudTagsPreviews = [...state.cloudTagsPreviews, action.payload];

      state.cloudTags = [...state.cloudTags, ...action.payload.split(' ')];
    },
    setCloudClicked: (state, action) => {
      state.clickedOnCloud = action.payload;
    },
    setCloudParams: (state, action) => {
      state.cloudTags = action.payload;
      state.clickedOnCloud = true;
    },
    setCloudParamPreview: (state, action) => {
      state.cloudTagsPreviews = action.payload;
    },
    removeCloudTag: (state, action) => {
      const newTags = state.cloudTagsPreviews.filter(
        (tag) => tag !== action.payload.removed
      );
      const splited = newTags.map((a) => a.split(' '));
      if ([...splited][0]) {
        state.cloudTags = [...splited][0] || [];
      } else {
        state.cloudTags = [...splited] || [];
      }
      state.cloudTagsPreviews = newTags || [];
    },
  },

  extraReducers: {
    [fetchSteps.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchSteps.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    [fetchSteps.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const {
  setGlobalCase,
  setCloudTags,
  removeCloudTag,
  setCloudClicked,
  setCloudParams,
  setCloudParamPreview,
} = stepSlice.actions;
export default stepSlice.reducer;
