/* eslint-disable object-curly-newline */
/* eslint-disable operator-linebreak */
import React, { useState } from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import useFindNext from '../Hooks/useFindNext';
import useTempTagHandler from '../Hooks/useTempTagHandler';
import woopraHandler from '../woopra';
import {
  setTempTag,
  toggleGhostList,
  updateSpesificFlow,
} from '../Redux/templateSlice';

const Wrapper = styled.div`
  align-items: flex-end;
  display: grid;
  font-family: 'Roboto', sans-serif;
  * {
    font-family: 'Roboto', sans-serif;
  }
  height: 50px;
  place-items: flex-end;
  position: relative;
  * {
    cursor: pointer;
  }
`;

export const WordStyle = styled.div`
  color: ${(props) => (props.onSelect ? 'gray' : '#33475b')};
  display: ${({ display }) => display};
  font-size: 2rem;
  font-size: 3rem;
  justify-self: center;
  line-height: 0.9;
  text-align: center;
  text-decoration: ${({ selected }) =>
    selected ? 'underline 1px #33475b' : 'none'};
`;
const UnderlineStyle = styled.div`
  color: #33475b;
  display: grid;
  line-height: 0.9;
  max-width: 400px;
  white-space: nowrap;
`;
const GhostListStyle = styled.ul`
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  font-size: 1.8rem;
  justify-self: flex-start;
  margin-top: 1rem;
  min-width: 250px;
  padding: 1rem;
  position: absolute;
  top: 100%;
  z-index: 1;
  li {
    border-radius: 8px;
    color: #333;
    list-style: none;
    padding: 1rem;
    white-space: nowrap;
    &:hover {
      background: #f5f5f5;
    }
  }
`;

function Word({
  choosen,
  text,
  tags,
  indexNumber,
  related,
  checkNextValue,
  checkNextExist,
  lastTag,
  lastIndex,
  finished,
  autocompleted,
  setPickedWord,
  setFinished,
}) {
  const { tempTagHandler } = useTempTagHandler();
  const [tempSelect, setTempSelect] = useState('');
  const state = useSelector((ctx) => ctx.template);
  const step = useSelector((ctx) => ctx.step);
  const { flow } = state;
  const dispatch = useDispatch();

  const flowUpdateHandler = (data) => {
    // Find index and update
    const index = state.flow.findIndex((item) => item.related === related);
    if (state.ghostList.length !== 1) {
      dispatch(updateSpesificFlow({ index, data: { choosen: true, ...data } }));
    }
  };
  return (
    <Wrapper>
      {choosen ? (
        <WordStyle
          selected={indexNumber !== 0 && !autocompleted}
          display={text?.length > 0 ? 'block' : 'none'}
          onClick={(e) => {
            // if (indexNumber === 0 || (finished && indexNumber === lastIndex))
            //   return;
            const checkWord = flow.filter((f) => f.related === related);
            if (autocompleted || indexNumber === 0) return;
            if (finished) {
              setFinished(false);
            }
            tempTagHandler([related]);
            dispatch(toggleGhostList());
          }}
        >
          {indexNumber === 0 ? text : text.toLowerCase()}
        </WordStyle>
      ) : (
        (checkNextExist(lastTag) && indexNumber !== lastIndex) ||
        (indexNumber !== 0 && (
          <UnderlineStyle onClick={() => dispatch(toggleGhostList())}>
            {tempSelect ? (
              <WordStyle onSelect>{tempSelect}</WordStyle>
            ) : (
              '_________________'
            )}
          </UnderlineStyle>
        ))
      )}
      {state.showGhostList && related === state.tempTag[0] && (
        <GhostListStyle
          onMouseLeave={() => {
            setTempSelect('');
          }}
        >
          {related === state.tempTag[0] &&
            state.showGhostList &&
            state.ghostList.map((ghost) => (
              <li
                onMouseEnter={() => {
                  const nextVal = checkNextValue(ghost.tags[0].name);
                  if (nextVal?.length === 1) {
                    return setTempSelect(
                      `${ghost.text.toLowerCase()} ${nextVal[0].text.toLowerCase()}`
                    );
                  }
                  return setTempSelect(ghost.text.toLowerCase());
                }}
                aria-hidden='true'
                onClick={() => {
                  flowUpdateHandler(ghost);
                  tempTagHandler(ghost?.tags?.map((item) => item?.name));
                  dispatch(toggleGhostList());
                  setPickedWord(ghost.text.toLowerCase());
                }}
                key={ghost._id}
              >
                {ghost.text.toLowerCase()}
              </li>
            ))}
        </GhostListStyle>
      )}
    </Wrapper>
  );
}

// props validation boolean required
Word.propTypes = {
  choosen: propTypes.bool.isRequired,
  text: propTypes.string.isRequired,
  tags: propTypes.isRequired,
  related: propTypes.string.isRequired,
  checkNextValue: propTypes.func.isRequired,
  checkNextExist: propTypes.func.isRequired,
  lastTag: propTypes.string.isRequired,
  indexNumber: propTypes.number.isRequired,
  finished: propTypes.bool.isRequired,
  lastIndex: propTypes.number.isRequired,
  autocompleted: propTypes.bool.isRequired,
  setPickedWord: propTypes.func.isRequired,
  setFinished: propTypes.func.isRequired,
};

export default React.memo(Word);
